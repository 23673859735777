<template>
  <div class="row q-col-gutter-sm">
    <div v-for="item in list" :key="item.productId" class="col-4">
      <q-card @click="getChainId(item)" class="my-card cursor">
        <q-img :src="item.img.length === 0 ? '' : 'https://r.xjip.info/media' + item.img[0]" style="height: 300px; width: 250x">
        </q-img>
        <div
          class="text-subtitle2 absolute-top text-center"
          style="
          background-color: rgba(0, 0, 0, 0.5); color: #FFF;
          display: flex; align-items: center; height: 50px;
          justify-content: center;
          "
          >
          <a>{{ item.name }}</a>
        </div>
        <!-- <div>{{item.name}}</div> -->
      </q-card>
    </div>
  </div>
</template>

<script>
import { QSpinnerGears } from 'quasar'
export default {
  name: "MarkList",
  props: {
    msg: {
      default: ''
    }
  },
  data() {
    return {
      itemName: null,
      list: []
    };
  },
  mounted() {
    this.getIndustryList();
  },
  watch: {
    msg(val) {
      if (val) {
        this.getIndustryList();
      }
    }
  },
  methods: {
    getChainId(item) {
      // this.$q
      //   .dialog({
      //     title: "提示",
      //     message: "功能开发中……"
      //   })
      //   .onOk(() => {
      //     // console.log('OK')
      //   })
      //   .onCancel(() => {
      //     // console.log('Cancel')
      //   })
      //   .onDismiss(() => {
      //     // console.log('I am triggered on both OK and Cancel')
      //   });
      this.$router.push({ name: "MarkProduct", params: { id: item.productId } });
    },
    getIndustryList() {
      this.$q.loading.show({
        delay: 100,
        message: '加载中...',
        spinner: QSpinnerGears
      })
      this.axios.get(process.env.VUE_APP_API_BASE_URL+'/landmark/city?city=' + this.msg)
        .then(res => {
          this.$q.loading.hide()
          if (res.data.code === 20000) {
            this.list = res.data.data;
          }
        })
    }
  }
};
</script>

<style scoped>
.cursor:hover {
  cursor: pointer;
}
</style>
