<template>
  <div class="chain" style="margin-bottom: 100px;">
    <div class="row">
      <div class="col-11">
        <q-breadcrumbs class="q-pb-md cursor" @click="goBack">
          <!-- <template v-slot:separator>
            <q-icon size="1.2em" name="arrow_forward" />
          </template> -->
          <q-breadcrumbs-el icon="chevron_left" label="返回上级" />
        </q-breadcrumbs>
        <mark-list :msg="msg" />
      </div>
    </div>
  </div>
</template>

<script>
import MarkList from "@/components/MarkList";

export default {
  name: "Chain",
  components: {
    MarkList
  },
  data () {
    return {
      msg: 0,
      breadList: []
    }
  },
  mounted () {
    this.msg = this.$route.params.id
    this.breadList = this.$route.matched
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor:hover {
  cursor: pointer;
}
</style>
